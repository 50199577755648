import {Action} from '../../../../common/types/MenuActions';
import {InvoicesRow} from '../../../modules/Invoices/InvoicesTable/invoicesTableConfig';
import * as React from 'react';
import {ReactNode, useMemo} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {InvoicePostingStatus, KSeFAuthResult, KSeFStatus, KSeFTokenCredential} from '@symfonia-ksef/graphql';
import {GroupedActionTypeEnum} from '../../../models';
import {SelectionI} from '../../../../root/services/TableServices/ExtendedTableService';
import {useInvoicesTableMethods} from '../../../hooks/useInvoicesTableMethods';
import {useInvoicesViewButtonsAndFiltersMethods} from '../../../hooks/useInvoicesViewButtonsAndFiltersMethods';
import {isSymfoniaTrade} from '../../../../common/helpers/checkCurrentPlatform';
import {isFeatureEnabled} from '../../../../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../../../../common/enums/FeatureSwitchTypeEnum';
import {earchiveStateMobx} from '../../../EArchiveModule';
import {IconColor, IconSvg} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {InvoicesFiltersState} from '../../../modules/Invoices/InvoicesFilters/InvoicesFiltersState';

export type InvoicesGroupActionsParams = {
  selectionState: SelectionI<InvoicesRow>;
  invoicesBound: { isExternal: boolean, isInternal: boolean };
  hasAllInvoicesWithDeprecatedSchemaVersion: boolean,
  setModalOpen: (open: boolean) => void,
  filters: InvoicesFiltersState
}

export function useInvoicesGroupActions(params: InvoicesGroupActionsParams): Action<InvoicesRow>[] {
  const intl = useIntl();
  const {isEmpty} = useInvoicesTableMethods(undefined, params.selectionState);
  const hasInvoiceWrite = earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceWrite);
  const authorizedInKSeF = earchiveState.company.authorizedInKSeF;

  const {isPackageWithInvoicesUsed} = earchiveState.packageStatistics;

  const {
    onGroupedAction,
    upoEnabled,
    exportToSymfoniaFKDisabled,
    deleteInvoicesButtonDisabled,
  } = useInvoicesViewButtonsAndFiltersMethods(params.selectionState);

  const isAnySelectedInvoiceToSend = !!params.selectionState.selected.find((invoice) => [KSeFStatus.NotSent, KSeFStatus.Error].includes(invoice.KSeFStatus));
  const enabledExportToSymfoniaTrade = isSymfoniaTrade();
  const enabledGetUpoPdf = upoEnabled;
  const enabledGetUpoXml = upoEnabled;
  const enabledSendToKSeF = isAnySelectedInvoiceToSend;
  const enabledPosting = isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && earchiveStateMobx.postingState.isPostingConfigured;

  const isSendToKSeFButtonDisabled =
    !enabledSendToKSeF ||
    authorizedInKSeF === KSeFAuthResult.NotAuthorized ||
    isPackageWithInvoicesUsed ||
    !hasInvoiceWrite ||
    isEmpty;

    const sendToKsefButtonTooltip = useMemo<ReactNode>(() => {
      if (params.hasAllInvoicesWithDeprecatedSchemaVersion) {
        return intl.formatMessage({id: Tr.invoiceSentToKsefDisabledCauseSchemaTooltip});
      }
      if (isSendToKSeFButtonDisabled) {
        return <>
            <b>{intl.formatMessage({id: Tr.noInvoiceWriteKsefSendTooltip_Header})}</b>
            <br/>
            <br/>
            {intl.formatMessage({id: Tr.noInvoiceWriteKsefSendTooltip_Content})}
          </>;
      }
      return intl.formatMessage({id: Tr.sendToKSeF});
  }, [isSendToKSeFButtonDisabled, params.hasAllInvoicesWithDeprecatedSchemaVersion, intl]);

  const areAllSelectedInvoicesNotAllowedInFK = !params.selectionState.selected.find((invoice) =>
    ![InvoicePostingStatus.NotAllowed, InvoicePostingStatus.Posting, InvoicePostingStatus.PostingFailed, InvoicePostingStatus.ReadyForPosting].includes(invoice.InvoicePostingStatus));

  const isExportToSymfoniaFKVisible = !isSymfoniaTrade && !areAllSelectedInvoicesNotAllowedInFK;

  return useMemo<Action<InvoicesRow>[]>(() => {
    if (params.invoicesBound.isExternal) {
      return [
        {
          testId: "whiteListMultipleAction",
          key: Tr.whiteListCheckAction,
          onClick: () => {
            onGroupedAction(GroupedActionTypeEnum.whiteListCheck);
          },
          tooltip: intl.formatMessage({id: Tr.whiteListCheckAction}),
          icon: IconSvg.PLAYLIST_ADD_CHECK,
          iconOptions: {color: IconColor.PRIMARY_500}
        },
        {
          testId: "downloadInvoiceMultipleAction",
          key: Tr.downloadInvoice,
          tooltip: intl.formatMessage({id: Tr.downloadInvoice}),
          icon: IconSvg.PUBLISH,
          iconOptions: {color: IconColor.PRIMARY_500, className: "rotate-180"},
          children: [
            {
              key: Tr.downloadInvoicePdf,
              label: intl.formatMessage({id: Tr.downloadInvoicePdf}),
              onClick: () => {
                onGroupedAction(GroupedActionTypeEnum.downloadInvoicesZip);
              },
              hidden: false,
              icon: IconSvg.SAVE_ALT,
              testId: "downloadInvoicePDFMultipleAction",
            },
          ],
        },
        {
          testId: "downloadToMultipleAction",
          key: Tr.downloadTo,
          tooltip: intl.formatMessage({id: Tr.downloadTo}),
          icon: IconSvg.CLOUD_DOWNLOAD,
          iconOptions: {color: IconColor.PRIMARY_500},
          children: [
            {
              testId: "exportToSymfoniaTradeMultipleAction",
              tooltipTitle: intl.formatMessage({id: Tr.exportToSymfoniaTrade_Tooltip}),
              disabled: !enabledExportToSymfoniaTrade,
              key: Tr.exportToSymfoniaTrade,
              label: intl.formatMessage({id: Tr.exportToSymfoniaTrade}),
              onClick: () => {
                onGroupedAction(GroupedActionTypeEnum.exportToSymfoniaTrade);
              },
              icon: IconSvg.SAVE_ALT,
            },
            {
              testId: "exportToSymfoniaFinancesAndAccountancyMultipleAction",
              key: Tr.exportToSymfoniaFinancesAndAccountancy,
              label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
              onClick: () => {
                onGroupedAction(GroupedActionTypeEnum.exportToSymfoniaFinancesAndAccountancy);
              },
              disabled: enabledPosting ? exportToSymfoniaFKDisabled : false,
              hidden: !isSymfoniaTrade,
              icon: IconSvg.SAVE_ALT,
            },
          ],
        },
        {
          testId: "autopostingMultipleAction",
          key: Tr.postInvoice,
          hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) || !earchiveStateMobx.postingState.isPostingConfigured,
          tooltip: intl.formatMessage({id: Tr.postInvoice}),
          icon: IconSvg.LIST_ALT,
          iconOptions: {color: IconColor.PRIMARY_500},
          onClick: () => {
            onGroupedAction(GroupedActionTypeEnum.post);
          },
        },
        {
          testId: "deleteInvoiceMultipleAction",
          key: Tr.removeButton,
          tooltip: intl.formatMessage({id: Tr.removeButton}),
          disabled: deleteInvoicesButtonDisabled,
          onClick: () => params.setModalOpen(true),
          icon: IconSvg.DELETE_OUTLINE,
          iconOptions: {color: IconColor.RED_500}
        },
      ];
    }
    return [
      {
        testId: "sendToKsefMultipleAction",
        key: Tr.sendToKSeF,
        tooltip: sendToKsefButtonTooltip,
        disabled: isSendToKSeFButtonDisabled || params.hasAllInvoicesWithDeprecatedSchemaVersion,
        icon: IconSvg.PUBLISH,
        iconOptions: {color: IconColor.PRIMARY_500},
        onClick: () => {
          onGroupedAction(GroupedActionTypeEnum.sendToKSeF);
        },
      },
      {
        testId: "downloadInvoiceMultipleAction",
        key: Tr.downloadInvoice,
        tooltip: intl.formatMessage({id: Tr.downloadInvoice}),
        icon: IconSvg.PUBLISH,
        iconOptions: {color: IconColor.PRIMARY_500, className: "rotate-180"},
        children: [
          {
            testId: "downloadInvoicePdfMultipleAction",
            key: Tr.downloadInvoicePdf,
            label: intl.formatMessage({id: Tr.downloadInvoicePdf}),
            icon: IconSvg.SAVE_ALT,
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.downloadInvoicesZip);
            },
          },
          {
            testId: "getUpoPdfMultipleAction",
            key: Tr.getUpoPdf,
            label: intl.formatMessage({id: Tr.getUpoPdf}),
            icon: IconSvg.SAVE_ALT,
            disabled: !enabledGetUpoPdf,
            tooltipTitle: !enabledGetUpoPdf ? intl.formatMessage({id: Tr.cantDownloadUpoTooltip}) : null,
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.getUpoPdf);
            },
          },
          {
            testId: "getUpoXmlMultipleAction",
            disabled: !enabledGetUpoXml,
            key: Tr.getUpoXml,
            label: intl.formatMessage({id: Tr.getUpoXml}),
            icon: IconSvg.SAVE_ALT,
            tooltipTitle: !enabledGetUpoXml ? intl.formatMessage({id: Tr.cantDownloadUpoTooltip}) : null,
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.getUpoXml);
            },
          },
        ],
      },
      {
        testId: "downloadToMultipleAction",
        key: Tr.downloadTo,
        tooltip: intl.formatMessage({id: Tr.downloadTo}),
        icon: IconSvg.CLOUD_DOWNLOAD,
        iconOptions: {color: IconColor.PRIMARY_500},
        disabled: enabledPosting ? isExportToSymfoniaFKVisible : false,
        children: [
          {
            testId: "exportToSymfoniaFinancesAndAccountancyMultipleAction",
            key: Tr.exportToSymfoniaFinancesAndAccountancy,
            label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
            onClick: () => {
              onGroupedAction(GroupedActionTypeEnum.exportToSymfoniaFinancesAndAccountancy);
            },
            disabled: enabledPosting ? exportToSymfoniaFKDisabled : false,
            hidden: enabledPosting ? !isExportToSymfoniaFKVisible : false,
            icon: IconSvg.SAVE_ALT,
          },
        ],
      },
      {
        testId: "autopostingMultipleAction",
        hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) || !earchiveStateMobx.postingState.isPostingConfigured,
        key: Tr.postInvoice,
        tooltip: intl.formatMessage({id: Tr.postInvoice}),
        icon: IconSvg.LIST_ALT,
        iconOptions: {color: IconColor.PRIMARY_500},
        onClick: () => {
          onGroupedAction(GroupedActionTypeEnum.post);
        },
      },
      {
        testId: "deleteInvoiceMultipleAction",
        tooltip: intl.formatMessage({id: deleteInvoicesButtonDisabled ? Tr.cantDeleteInvoiceTooltip : Tr.removeButton}),
        key: Tr.removeButton,
        disabled: deleteInvoicesButtonDisabled,
        icon: IconSvg.DELETE_OUTLINE,
        iconOptions: {color: IconColor.RED_500},
        onClick: () => params.setModalOpen(true),
      },
    ];
  }, [params.selectionState, onGroupedAction, params.invoicesBound.isExternal, deleteInvoicesButtonDisabled, earchiveStateMobx.postingState.isPostingConfigured, exportToSymfoniaFKDisabled, enabledExportToSymfoniaTrade, isExportToSymfoniaFKVisible, enabledGetUpoXml, enabledGetUpoPdf, sendToKsefButtonTooltip, isSendToKSeFButtonDisabled, params.hasAllInvoicesWithDeprecatedSchemaVersion]);
}
