import {FC, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {DownloadInvoicesButtonState} from './DownloadInvoicesButtonState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage} from 'react-intl';
import {Tooltip} from '@symfonia/brandbook';
import {LoadingButton, LoadingButtonSize} from '@symfonia/symfonia-ksef-components';
import {useModule} from '../../../../root/services/MobXServices/BaseModule';
import {downloadedInvoicesResultService, downloadInvoicesProgressTracker} from '@symfonia-ksef/state/rootRepository';
import {InvoicesDownloadIsRunningModal} from '../modals/InvoicesDownloadIsRunningModal';

export interface DownloadInvoicesButtonProps {
  isEnabled: boolean;
  environmentId: string;
  onDownloaded: () => Promise<void> | void;
  hasInvoiceReadCredential?: boolean;
}

export const DownloadInvoicesButton: FC<DownloadInvoicesButtonProps> = observer(({
                                                                                   isEnabled,
                                                                                   environmentId,
                                                                                   onDownloaded,
                                                                                   hasInvoiceReadCredential,
                                                                                 }) => {
  const state = useModule<DownloadInvoicesButtonState>(() => new DownloadInvoicesButtonState(downloadedInvoicesResultService, downloadInvoicesProgressTracker));
  const isButtonDisabled = useMemo(() => !isEnabled || state.isPackageWithInvoicesNotActive || !hasInvoiceReadCredential, [isEnabled, hasInvoiceReadCredential, state.isPackageWithInvoicesNotActive]);

  const tooltipTitle = <div>
    <strong>
      <FormattedMessage
        id={Tr.noInvoiceReadCredentialKsefDownloadTooltip_Header}/>
    </strong>
    <br/>
    <FormattedMessage
      id={Tr.noInvoiceReadCredentialKsefDownloadTooltip_Content}
    />
  </div>;

  const [{title, onClick}] = state.actions;

  const renderLoadingButton = useCallback(() => {
    return (
      <div>
        <LoadingButton
          loading={state.loading}
          text={title}
          onClick={onClick}
          size={LoadingButtonSize.SM}
          disabled={isButtonDisabled}
          testId="downloadFromKsefButton"/>
      </div>
    );
  }, [isButtonDisabled, state.loading, title, onClick]);

  return (
    <div className="ml-[8px] mb-[8px]">
      <InvoicesDownloadIsRunningModal downloadChecker={downloadInvoicesProgressTracker}/>
      {!isButtonDisabled ? renderLoadingButton() : (
        <Tooltip text={tooltipTitle}>
          {renderLoadingButton()}
        </Tooltip>
      )}
    </div>
  );
});



